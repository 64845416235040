import React from 'react';
import '../../../styles/ConfirmModal.scss';

const ConfirmModal = ({ title, onClose }) => {
    return (
        <section className="confirm-section">
            <div className="inner">
                <figure
                    className="close-modal-btn"
                    onClick={onClose}
                >
                    <img
                        src={'/images/close.png'}
                        alt=""
                    />
                </figure>
                <figure className="confirm-images">
                    <img
                        src={'/images/confirm-modal-img.svg'}
                        alt="test"
                    />
                </figure>
                <div className="confirm-txt-container">
                    <p className="confirm-txt">
                        멋진 선택이었어요, 친구들! <br /> <em>"{title}"</em>의
                        새로 만들어진 이야기가 <em>완성</em>되었답니다.
                    </p>
                    <button onClick={onClose}>동화 보기</button>
                </div>
            </div>
        </section>
    );
};

export default ConfirmModal;
