import React, { useState, useEffect, useRef } from 'react';
import '../../../styles/FinalModal.scss';

//NOTE: Internal Dependencies.
import {
    callFalaiAPIforBackground,
    callChatGPTAPIforTitle,
    callChatGPTAPIforBookcover,
    saveCompletedBook,
} from '../../../api/StoryAPI';
import Loading from '../loading/Loading';
import CompleteModal from '../../creating/modal/CompleteModal';
import { useParams, useNavigate } from 'react-router-dom';

const FinalModal = ({ contentList, story_id, branchNum, onClose }) => {
    const { selectedBookId } = useParams();
    // const { USER } = useAuth();
    const USER_ID = localStorage.getItem('userID');
    console.log('USER_ID in FinalModal: ', USER_ID);
    const USER_NAME = localStorage.getItem('userName');
    const navigate = useNavigate();

    const [bookTitle, setbookTitle] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [generateEnd, setGenerateEnd] = useState(false);
    const [loadingTitle, setLoadingTitle] = useState('');
    const [loadingFlag, setLoadingFlag] = useState('');
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [isContentVisible, setIsContentVisible] = useState(true);
    // 2024.01.23 | 표지 만들기 상태 추가
    const [showCoverButton, setshowCoverButton] = useState(false);
    // 2024.01.23 | CompleteModal 상태
    const [showCompleteModal, setShowCompleteModal] = useState(false);
    const [titleMade, setTitleMade] = useState(false);
    const [coverMade, setCoverMade] = useState(false);

    // 오디오 요소에 대한 참조 생성
    const audioRef = useRef(null);

    const controlLoading = (on) => {
        if (on) {
            setGenerateEnd(false);
            setShowLoading(true);
        } else {
            setGenerateEnd(true);
            setShowLoading(false);
        }
    };

    // const goLibrary = () => {
    //     navigate('/myStorybook');
    // };

    const handleGoBack = () => {
        onClose();
    };

    useEffect(() => {
        console.log(`bookTitle.length: ${bookTitle.length}`);
        console.log(`backgroundImage.length: ${backgroundImage.length}`);
        if (bookTitle.length != 0 && backgroundImage.length != 0) {
            setIsContentVisible(false);
            setIsButtonEnabled(true);
        }
    }, [bookTitle, backgroundImage]);

    const handleMakeTitle = async () => {
        setLoadingTitle('특별한 동화 제목을 만드는 중이예요!');
        setLoadingFlag('title');
        controlLoading(true);

        if (audioRef.current) {
            audioRef.current.volume = 0.6; // 볼륨을 60%로 설정
            audioRef.current.play().catch((error) => {
                console.error('오디오 재생에 실패했습니다.', error);
            });
        }

        console.log('@@contentList => ', contentList);
        let stories = '';
        for (const content of contentList) {
            stories = stories + ' ' + content.page_content;
        }
        console.log('@@stories => ', stories);
        let data = {
            user_id: USER_ID,
            story_id: selectedBookId,
            stories: stories,
        };
        let res = await callChatGPTAPIforTitle(data);
        if (res.success) {
            let title = res.data;
            console.log('@@title => ', title);
            if (title.includes('"')) title = title.replaceAll('"', '');

            setTimeout(() => {
                setbookTitle(title);
                controlLoading(false);
                setshowCoverButton(true);
                setTitleMade(true);
            }, 3000); // 10000ms = 10초
        } else {
            alert('잠시 오류가 발생했습니다. 다시 실행해주세요.');
            setShowLoading(false);
            setshowCoverButton(false);
        }
    };

    const handleSetBackgroundImage = async () => {
        setLoadingTitle('동화의 세계가 드디어 완성되어요!');
        setLoadingFlag('title');
        controlLoading(true);
        await generateBookcover();
    };

    const generateBookcover = async () => {
        let stories = '';
        for (const content of contentList) {
            stories = stories + ' ' + content.page_content;
        }
        let data = {
            user_id: USER_ID,
            story_id: selectedBookId,
            stories: stories,
        };
        let res_chatgpt = await callChatGPTAPIforBookcover(data);
        if (res_chatgpt.success) {
            console.log(typeof res_chatgpt.data);
            let resJson;
            if (typeof res_chatgpt.data === 'object') {
                resJson = res_chatgpt.data;
            } else {
                console.log('@@@@@@ =>', res_chatgpt.data);
                resJson = JSON.parse(res_chatgpt.data);
            }

            let generatedPrompt = {
                background_prompt: resJson.background_prompt,
                character_prompt: resJson.character_prompt,
            };
            console.log('@@ generateBookcover => ', generatedPrompt);
            let prompts = [
                {
                    background_prompt: generatedPrompt.background_prompt,
                    character_prompt: generatedPrompt.character_prompt,
                    image_size: 'landscape_4_3',
                    num_images: 1,
                },
            ];
            console.log('@@ prompts => ', prompts);
            let res = await callFalaiAPIforBackground(
                selectedBookId,
                prompts,
                USER_ID,
                'storybook_cover'
            );
            if (res.success) {
                console.log('@@ callFalaiAPIforBackground => ', res.data);
                console.log('@@ url => ', res.data[0][0].url);

                setBackgroundImage(res.data[0][0].url);
                controlLoading(false);
                if (audioRef.current) {
                    audioRef.current.play().catch((error) => {
                        console.error('오디오 재생에 실패했습니다.', error);
                    });
                }
                setCoverMade(true);
            } else {
                setShowLoading(false);
                if (
                    res.error.errCode === 'CHATGPT_ERR_00' ||
                    res.error.errCode === 'DATA_ERR_00'
                ) {
                    console.log('@@ err => ', res.error.errCode);
                    // 오류 처리
                }
                console.log('@@ err => ', res.error);
            }
        } else {
            alert('잠시 오류가 발생했습니다. 다시 실행해주세요.');
            setShowLoading(false);
        }
    };

    const handleCompletedBtn = async () => {
        // onCompleted(bookTitle, backgroundImage)
        setIsButtonEnabled(false);
        let data = {
            story_id: selectedBookId,
            title: bookTitle,
            bookcover: backgroundImage,
            creator: USER_ID,
            page_count: contentList.length,
            contents_json: contentList,
            branchNum: branchNum,
        };
        console.log(`completedBook body = ${JSON.stringify(data)}`);
        let res = await saveCompletedBook(data);
        if (res.success) {
            // goLibrary();
            console.log('@@@data = > ', res.data);
            setShowCompleteModal(true);
        } else {
            console.log('ERR: ', res.error);
            alert('잠시 오류가 발생했습니다. 다시 실행해주세요.');
            setIsButtonEnabled(true);
        }
    };

    return (
        <section
            className="final-modal-section"
            style={
                backgroundImage.length === 0
                    ? { backgroundImage: backgroundImage }
                    : { backgroundImage: `url(${backgroundImage})` }
            }
        >
            <audio
                ref={audioRef}
                src="/audio/twinkle-storybook-final.mp3"
            />
            {(isContentVisible || !isButtonEnabled) && (
                <>
                    <div className="loader"></div>
                    {!isButtonEnabled && backgroundImage && bookTitle && (
                        <p className="sub-title">
                            동화를 저장 중 입니다. 조금만 기다려주세요!
                        </p>
                    )}
                </>
            )}
            {/* 책 제목이 완성되면 특정 클래스명 추가하기 */}
            {bookTitle && !backgroundImage && (
                <p className="title-completed-notice">제목이 완성되었습니다</p>
            )}
            <h2 className={`final-title ${bookTitle ? 'sucess' : ''}`}>
                {bookTitle || '제목을 만들어주세요'}
            </h2>
            {/* 제목이 설정되었고, 표지 이미지가 아직 없을 때 "표지를 만들어주세요" 텍스트와 버튼을 보여줌 */}
            {bookTitle && !backgroundImage && (
                <p className="cover-instruction-notice blink">
                    표지를 만들어주세요
                </p>
            )}
            <div
                className={`container-flex ${
                    titleMade && coverMade ? 'reverse' : ''
                }`}
            >
                <div className="choose-btn-container">
                    <button
                        className="make-buttons make-title-button"
                        onClick={() => {
                            handleMakeTitle();
                        }}
                    >
                        {bookTitle ? '제목 다시 만들기' : '제목 만들기'}
                    </button>
                    {showCoverButton && (
                        <button
                            className="make-buttons make-cover-button"
                            onClick={() => {
                                handleSetBackgroundImage();
                            }}
                        >
                            {backgroundImage
                                ? '표지 다시 만들기'
                                : '표지 만들기'}
                        </button>
                    )}
                </div>
                <button
                    className={`complete ${isButtonEnabled ? 'on' : ''}`}
                    disabled={!isButtonEnabled}
                    onClick={handleCompletedBtn}
                >
                    만든 그림동화 저장하기
                </button>
            </div>
            <div
                className="go-back"
                onClick={handleGoBack}
            >
                뒤로가기
            </div>

            {showLoading && (
                <Loading
                    title={loadingTitle}
                    end={generateEnd}
                    flag={loadingFlag}
                    onEnd={() => setShowLoading(false)}
                />
            )}
            {showCompleteModal && (
                <CompleteModal
                    contents_json={contentList}
                    title={bookTitle}
                    backgroundImage={backgroundImage}
                />
            )}
        </section>
    );
};

export default FinalModal;
