import React, { useState } from 'react';
import '../../styles/SelectingList.scss';
import PlotModal from '../utils/PlotModal';
import TextToSpeech from '../utils/TextToSpeech';

const SelectingList = ({ bookData, onBookSelect, onChooseClick }) => {
    // 2023.11.28 추가 | 동화 리스트 컴포넌트

    const [isPlotModalOpen, setIsPlotModalOpen] = useState(false); //plotModal open,close 관리
    const [plotSelectedBook, setPlotSelectedBook] = useState(null); //"줄거리보기" 클릭 시, plotModal에서 보여줄 도서 정보 저장

    const [speechState, setSpeechState] = useState(bookData.map(() => false));

    const openPlotModal = () => {
        setIsPlotModalOpen(true);
    };
    const closePlotModal = () => {
        setIsPlotModalOpen(false);
    };
    //   PlotModal 열고 닫기 함수

    const handleSelectClick = (story_id) => {
        const selectedBook = bookData.find(
            (book) => book.story_id === story_id
        );
        onBookSelect(selectedBook);
    };

    const handlePlotReadClick = (clickedBook) => {
        console.log('모달@! ', clickedBook);
        setPlotSelectedBook(clickedBook);
        if (clickedBook.description != '수정 필요') openPlotModal();
        else alert('해당 동화는 준비중입니다.');
    };
    //   plotModal에 있는 "줄거리보기" 버튼 눌렀을때
    //   도서 정보 저장 후 plotModal 열기

    const handleChooseClick = (selectedBook) => {
        onChooseClick(selectedBook);
        closePlotModal();
    };
    //   plotModal에 있는 "선택하기" 버튼 눌렀을때

    // 음성 재생 상태를 토글
    const toggleSpeechState = (index) => {
        const update_speech_states = [...speechState];
        update_speech_states[index] = !update_speech_states[index];
        setSpeechState(update_speech_states);
    };

    return (
        <>
            {bookData.map((book, i) => (
                // <div
                //     // className="book-item-test"
                //     key={book.id}
                // >
                <>
                    <li
                        key={book.book_id}
                        className="fairy-tale-item"
                    >
                        <figure className="book-cover">
                            <img
                                src={book.bookcover}
                                alt="동화책 커버"
                            />
                        </figure>
                        <figure className="book-bottom">
                            <img
                                src="/images/book-cover-bottom.svg"
                                alt="동화책 책 모양 배경"
                            />
                        </figure>
                        <div className="book-name-container">
                            <h3 className="book-title">{book.title}</h3>
                        </div>
                        <figure className="bean-circle">
                            <img
                                src={'/images/bean-circle.svg'}
                                alt=""
                            />
                        </figure>
                        <div className="hover-btns">
                            <p
                                className="hover-btn choose"
                                onClick={() => {
                                    handleSelectClick(book.story_id);
                                }}
                            >
                                <span>선택하기</span>
                                <figure>
                                    <img
                                        src={'/images/gold-bean.svg'}
                                        alt=""
                                    />
                                </figure>
                            </p>
                            {/* 선택된 id를 stories로 넘기기 */}
                            <p
                                className="hover-btn around"
                                onClick={() => {
                                    handlePlotReadClick(book);
                                }}
                            >
                                줄거리보기
                            </p>

                            {/* id,title,cover,plot 다 넘기기 -> PlotModal에서 받고 확인 -> PlotModal에서 stories로 book_id 넘기기*/}
                        </div>
                    </li>
                    {/* <TextToSpeech
                        text={book.title}
                        // props 추가
                        speechState={speechState[i]}
                        onChange={() => toggleSpeechState(i)}
                    /> */}
                    {/* </div> */}
                </>
            ))}

            {isPlotModalOpen && (
                <PlotModal
                    clickedBook={plotSelectedBook}
                    onClose={closePlotModal}
                    onChooseClick={handleChooseClick}
                />
            )}
        </>
    );
};

export default SelectingList;
