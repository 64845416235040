import React from 'react';
import '../../styles/Floating.scss';
import { useLocation } from 'react-router-dom';

const Floating = () => {
    const location = useLocation();

    let image_src = '/images/test-box.svg';
    if (location.pathname === '/myStorybook') {
        image_src = '/images/test-box-3.svg';
    } else if (location.pathname === '/storyland') {
        image_src = '/images/test-box-2.svg';
    }

    return (
        <>
            <div className="floating-btn">
                <div className="floating-container">
                    <figure className="bubble">
                        <img
                            src={'/images/float-bubble.svg'}
                            alt="float-bubble"
                        />
                    </figure>
                    <figure
                        className="char"
                        onClick={() => {
                            window.close();
                        }}
                    >
                        <img
                            src={'/images/float-char.svg'}
                            alt="float-char"
                        />
                    </figure>
                </div>
                <div
                    className="back-magickid"
                    onClick={() => {
                        window.close();
                    }}
                >
                    <img
                        src={image_src}
                        alt=""
                    />
                </div>
            </div>
        </>
    );
};

export default Floating;
