import React from 'react';
import '../../styles/PlotModal.scss';

const PlotModal = ({ clickedBook, onClose, onChooseClick }) => {
    const handleChooseClick = () => {
        onChooseClick(clickedBook);
        onClose();
    };
    // PlotModal에서 "선택하기" 눌렀을때 동작하는 함수

    const insertLineBreaks = (text, maxSyllables) => {
        const regex = new RegExp(`.{1,${maxSyllables}}`, 'g');
        return text.match(regex).join('\n');
    };
    // 한글 15음절 단위로 줄바꿈

    return (
        <>
            <section className="plot-modal-section">
                <div className="inner">
                    <div className="plot-modal">
                        <figure
                            className="close-btn"
                            onClick={onClose}
                        >
                            <img
                                src={'/images/close.png'}
                                alt="close-btn"
                            />
                        </figure>
                        <div className="flex-container">
                            <div className="book-preview">
                                <figure className="book-cover">
                                    <img
                                        src={clickedBook.bookcover}
                                        alt=""
                                    />
                                </figure>
                                <figure className="book-bottom">
                                    <img
                                        src="/images/book-cover-bottom.svg"
                                        alt=""
                                    />
                                </figure>
                                <div className="book-name-container">
                                    <h3 className="book-title">
                                        {clickedBook.title}
                                    </h3>
                                </div>
                            </div>
                            <h3 className="title">{clickedBook.title}</h3>
                        </div>
                        {/* <div className="plot-container">
              <h3 className="title">{clickedBook.title}</h3> */}
                        <div className="plot-container">
                            {clickedBook.original && (
                                <>
                                    <h3 className="origin-story">원작스토리</h3>
                                    <p className="origin-story-desc">
                                        {insertLineBreaks(
                                            clickedBook.original,
                                            15
                                        )}
                                    </p>
                                </>
                            )}

                            <p className="story-begin">
                                어떤 내용이 이어질까요?
                            </p>
                            <p className="desc">
                                {insertLineBreaks(clickedBook.description, 15)}
                                {/* 2023.11.28 수정 | 한국어 15음절 단위로 줄바꿈 */}
                            </p>
                            <div
                                className="choose-btn"
                                onClick={handleChooseClick}
                            >
                                <span>선택하기</span>
                                <figure>
                                    <img
                                        src={'/images/gold-bean.svg'}
                                        alt=""
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PlotModal;
