import React, { useState, useEffect } from 'react';
// 2024-02-06 | css import
import '../../styles/TextToSpeech.scss';

const TextToSpeech = ({ text, speechState, onChange }) => {
    const [isPaused, setIsPaused] = useState(false);
    // 2024-02-06 | 재생하기 텍스트 대신 이미지로 변경, state명 변경
    const [imageSrc, setImageSrc] = useState('');
    const [utterance, setUtterance] = useState(null);

    useEffect(() => {
        const synth = window.speechSynthesis;
        const newUtterance = new SpeechSynthesisUtterance(text);
        newUtterance.onend = () => {
            // 음성 재생이 끝나면 호출됩니다.
            setIsPaused(false);
            // 2024-02-06 | 텍스트 -> 이미지로 변경, state명 변경
            setImageSrc('/images/music-on.svg');
        };
        setUtterance(newUtterance);

        if (speechState) {
            setImageSrc('/images/music-off.svg');
            const timeoutId = setTimeout(() => {
                synth.speak(newUtterance);
            }, 500); // 0.5초 후에 음성을 재생

            return () => {
                clearTimeout(timeoutId); // 타이머 정리
                synth.cancel(); // 컴포넌트가 언마운트되거나 text가 변경될 때, 음성 합성을 중지합니다.
            };
        } else {
            setImageSrc('/images/music-on.svg');
        }

        return () => {
            synth.cancel(); // 컴포넌트가 언마운트되거나 text가 변경될 때, 음성 합성을 중지합니다.
        };
    }, [text, speechState]);

    const handlePlay = (event) => {
        event.stopPropagation();
        const synth = window.speechSynthesis;

        if (speechState) {
            synth.cancel();
            // 2024-02-06 | 텍스트 -> 이미지로 변경, state명 변경
            setImageSrc('images/music-on.svg');
            setIsPaused(false);
        } else {
            synth.speak(utterance);
            // 2024-02-06 | 텍스트 -> 이미지로 변경, state명 변경
            setImageSrc('images/music-off.svg');
            setIsPaused(true);
        }
    };

    const handlePause = () => {
        const synth = window.speechSynthesis;
        synth.pause();
        setIsPaused(true);
    };

    const handleStop = (event) => {
        event.stopPropagation();
        const synth = window.speechSynthesis;
        synth.cancel();
        setIsPaused(false);
    };

    return (
        // 2024-02-06 | div태그 지우고 img 태그 추가함, button에 class명 추가
        <button
            onClick={(event) => {
                event.stopPropagation(); // 이벤트 전파 중지
                onChange(); // 원래의 onChange 함수 호출
            }}
            className="text-music-btn"
        >
            <img
                src={imageSrc}
                alt="test"
            />
        </button>
    );
};

export default TextToSpeech;
