import React from 'react';
import '../../../styles/BackwardModal.scss';

const BackwardModal = ({ onBack, onClose }) => {
    return (
        <section className="backward-modal">
            <div className="inner">
                <figure>
                    <img
                        src={'/images/book_alert.svg'}
                        alt=""
                    />
                </figure>
                <div className="backward-txt-container">
                    <p>
                        이전 페이지로 돌아갈 경우, <br />
                        <em>현재 만들어진 동화 내용은 사라져요!</em>
                    </p>
                    <div className="btn-container">
                        <button
                            className="go-back"
                            onClick={onBack}
                        >
                            이전 페이지로 이동
                        </button>
                        <button
                            className="on-stay"
                            onClick={onClose}
                        >
                            현재 페이지에 있기
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BackwardModal;
