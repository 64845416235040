import React, { useState, useEffect } from 'react';
import '../styles/StoryLand.scss';
import BookItem from '../components/utils/BookItem';

//NOTE: Internal Dependencies.
import { getStoryLandList } from '../api/LibraryAPI';
import Floating from '../components/utils/Floating';
import loginSessionCheck from '../components/utils/LoginSessionCheck';
import LandScapeModal from '../components/utils/modal/LandScapeModal';
import ErrorModal from '../components/utils/modal/ErrorModal';
import { ERROR } from '../utils/Constants';
import SeriesBookItems from '../components/storyland/SeriesBookItems';

const StoryLand = () => {
    const [bookList, setbookList] = useState([]);
    const [displayedBooks, setDisplayedBooks] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('all-books');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        switch (selectedCategory) {
            case 'all-books':
                setDisplayedBooks(bookList);
                break;
            case 'single-book':
                setDisplayedBooks(bookList.filter((book) => !book.is_series));
                break;
            case 'series-books':
                setDisplayedBooks(bookList.filter((book) => book.is_series));
                break;
            default:
                setDisplayedBooks(bookList);
        }
    }, [selectedCategory, bookList]);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    useEffect(() => {
        callStoryLandListAPIFunc();
    }, []);

    //NOTE: 스토리랜드 창작동화 리스트 API 호출 함수
    const callStoryLandListAPIFunc = () => {
        const response = getStoryLandList();

        response.then((res) => {
            if (res.success) {
                console.log('@@ data =>', res.data);
                setbookList(res.data);
            } else {
                setShowErrorModal(true);
                setErrorMessage(ERROR.busy);
                console.log(res.error.errMsg);
            }
        });
    };

    const handleGoChainTree = () => {
        window.open(`https://chaintree.biz`, '_blank', 'noopener,noreferrer');
    };

    return (
        <>
            {showErrorModal && (
                <ErrorModal
                    error_message={errorMessage}
                    onClose={() => setShowErrorModal(false)}
                />
            )}
            <section className="storyland-section">
                <div className="inner">
                    <div className="library-title">
                        <h3>스토리랜드</h3>
                        <h6>
                            다양한 체인트리의 <em>📚 창작 그림동화 📚</em>를 볼
                            수 있어요!
                        </h6>
                    </div>
                    <ul className="book-category">
                        <li
                            className={`all-books ${
                                selectedCategory === 'all-books'
                                    ? 'selected'
                                    : ''
                            }`}
                            onClick={() => {
                                handleCategoryClick('all-books');
                            }}
                        >
                            <figure>
                                <img
                                    src={'/images/all-books.svg'}
                                    alt="all-books"
                                />
                            </figure>
                            <span>전체 동화</span>
                        </li>
                        <li
                            className={`single-book ${
                                selectedCategory === 'single-book'
                                    ? 'selected'
                                    : ''
                            }`}
                            onClick={() => {
                                handleCategoryClick('single-book');
                            }}
                        >
                            <figure>
                                <img
                                    src={'/images/single-book.svg'}
                                    alt="single-book"
                                />
                            </figure>
                            <span>단편 동화</span>
                        </li>
                        <li
                            className={`series-books ${
                                selectedCategory === 'series-books'
                                    ? 'selected'
                                    : ''
                            }`}
                            onClick={() => {
                                handleCategoryClick('series-books');
                            }}
                        >
                            <figure>
                                <img
                                    src={'/images/series-books.svg'}
                                    alt="series-books"
                                />
                            </figure>
                            <span>시리즈 동화</span>
                        </li>
                    </ul>
                    <div
                        className={`book-list ${
                            selectedCategory === 'series-books' ? 'column' : ''
                        }`}
                    >
                        {/* <BookItem
                            bookList={displayedBooks}
                            pageName="storyland"
                        /> */}
                        {/* "전체 동화" 탭에는 모든 책을 표시 */}
                        {selectedCategory === 'all-books' && (
                            <BookItem
                                bookList={displayedBooks}
                                pageName="storyland"
                            />
                        )}

                        {/* "단편 동화" 탭에는 단편 동화만 표시 */}
                        {selectedCategory === 'single-book' && (
                            <BookItem
                                // bookList={displayedBooks.filter(
                                //     (book) => !book.is_series
                                // )}
                                bookList={displayedBooks}
                                pageName="storyland"
                            />
                        )}

                        {/* "시리즈 동화" 탭에는 시리즈 동화만 표시, SeriesBookItems 컴포넌트 사용 예시 */}
                        {selectedCategory === 'series-books' && (
                            <SeriesBookItems
                                // bookList={displayedBooks.filter(
                                //     (book) => book.is_series
                                // )}
                                bookList={displayedBooks}
                                pageName="storyland"
                            />
                        )}
                    </div>
                </div>
                <figure className="bottom">
                    <img
                        src="/images/storyland-bottom.svg"
                        alt="storyland-bottom"
                    />
                </figure>
                {/* 2024-02-14 | footer-txt 추가 */}
                <div className="footer-txt">
                    <p
                        className="site"
                        onClick={handleGoChainTree}
                    >
                        www.chaintree.biz
                    </p>
                    <p>© Copyright ChainTree Inc. All Rights Reserved</p>
                </div>
                <Floating />
            </section>
            <LandScapeModal />
        </>
    );
};

export default loginSessionCheck(StoryLand);
