import React, { useRef, useEffect, useState } from 'react';
import '../../../styles/BookLoading.scss';
import ProgressBar from '../ProgressBar';
//NOTE: Internal Dependencies.
import { getStoryLandforLoading } from '../../../api/LibraryAPI';
import OngoingModal from '../modal/OngoingModal';

const BookLoading = ({ title, flag, end, onEnd, timeCount }) => {
    const [countDown, setCountDown] = useState(timeCount);
    const [isBackgroundImageChanged, setIsBackgroundImageChanged] =
        useState(false); //NOTE: completed되면 배경 변함
    const [isPlaying, setIsPlaying] = useState(true);

    const [storylandBookList, setStorylandBookList] = useState(); //NOTE: 전체 동화 리스트(총 5개)
    const [storylandBook, setStorylandBook] = useState(null); //NOTE: 현재 화면에 출력하는 동화 그림
    const [storylandBookText, setStorylandBookText] = useState(null); //NOTE: 현재 화면에 출력하는 동화 내용
    const [storylandIndex, setStorylandIndex] = useState(); //NOTE: 현재 화면에 출력하는 동화 인덱스
    const [storylandBookListIndex, setStorylandBookListIndex] = useState(); //NOTE: 전체 동화에서 출력하고 있는 동화 인덱스

    // 2024-02-16 | OngoingModal
    const [showOngoingModal, setShowOngoingModal] = useState(false);

    // 오디오 요소에 대한 참조 생성
    const audioRef = useRef(null);

    const handleProgressComplete = () => {
        // setIsBackgroundImageChanged(true);
    };
    const handleAudioPlay = () => {
        //NOTE: 2024-01-23 수정 오디오 on/off 활성화
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.play().catch((error) => {
                    console.error('오디오 재생에 실패했습니다.', error);
                });
            } else {
                audioRef.current.pause();
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        callStoryLandAPIFunc();
    }, []);

    //NOTE: 로딩을 위한 스토리랜드 랜덤 5개 조회 API 호출 함수
    const callStoryLandAPIFunc = () => {
        const response = getStoryLandforLoading();

        response.then((res) => {
            if (res.success) {
                console.log('@@ datas =>', res.data);
                // return res.data
                setStorylandBookList(res.data);
                console.log(
                    '@@ storylandBook bookcover =>',
                    res.data[0].bookcover
                );
                console.log('@@ storylandBook title =>', res.data[0].title);
                console.log(
                    '@@ storylandBook contents_json.story =>',
                    res.data[0].contents_json.story
                );
                setStorylandBook(res.data[0].bookcover);
                setStorylandBookText(res.data[0].title);
                setStorylandIndex(-1);
                setStorylandBookListIndex(0);
            } else {
                console.log(res.error.errMsg);
                //NOTE: 2024-01-24 에러처리 필요 [수정]
            }
        });
    };

    console.log('storylandIndex => ', storylandIndex);
    console.log('storylandBookListIndex => ', storylandBookListIndex);

    useInterval(() => {
        setStorylandIndex((prevIndex) => {
            if (
                prevIndex >= 9 &&
                storylandBookListIndex < storylandBookList.length - 1
            ) {
                setStorylandBookListIndex(storylandBookListIndex + 1);
                return -1;
            } else if (prevIndex >= -1 && prevIndex < 9) return prevIndex + 1;
            return 9;
        });
    }, 6000);

    function useInterval(callback, delay) {
        const savedCallback = useRef(); // 최근에 들어온 callback을 저장할 ref를 하나 만든다.

        useEffect(() => {
            savedCallback.current = callback; // callback이 바뀔 때마다 ref를 업데이트 해준다.
        }, [callback]);

        useEffect(() => {
            function tick() {
                savedCallback.current(); // tick이 실행되면 callback 함수를 실행시킨다.
            }
            if (delay !== null) {
                // 만약 delay가 null이 아니라면
                let id = setInterval(tick, delay); // delay에 맞추어 interval을 새로 실행시킨다.
                return () => clearInterval(id); // unmount될 때 clearInterval을 해준다.
            }
        }, [delay]); // delay가 바뀔 때마다 새로 실행된다.
    }

    // storylandIndex 변경에 따른 콘텐츠 업데이트
    useEffect(() => {
        if (storylandBookList == null) return;
        if (storylandIndex === -1) {
            // 현재 책표지 보이기
            setStorylandBook(
                storylandBookList[storylandBookListIndex].bookcover
            );
            setStorylandBookText(
                storylandBookList[storylandBookListIndex].title
            );
        } else if ((storylandIndex) => 0 && storylandIndex < 10) {
            // 현재 책내용 보이기
            setStorylandBook(
                storylandBookList[storylandBookListIndex].contents_json.story[
                    storylandIndex
                ].background
            );
            setStorylandBookText(
                storylandBookList[storylandBookListIndex].contents_json.story[
                    storylandIndex
                ].page_content
            );
        }
    }, [storylandIndex, storylandBookListIndex]);

    // 오른쪽 버튼 클릭 핸들러
    const handleRightButtonClick = () => {
        setStorylandIndex((prevIndex) => {
            if (
                prevIndex === -1 ||
                prevIndex === 0 ||
                (prevIndex > 0 && prevIndex < 9)
            ) {
                return prevIndex + 1;
            } else if (prevIndex >= 9) {
                console.log(storylandBookListIndex);
                // 다음 책으로 넘어갈것
                // 마지막 책에서 다음 버튼 눌렀을때 아무일도 안일어나
                if (storylandBookListIndex !== storylandBookList.length - 1) {
                    setStorylandBookListIndex(storylandBookListIndex + 1);
                    return -1;
                } else return 9;
            }
            return 9;
        });
        // setStorylandIndex(prevIndex => prevIndex >= 10 ? 10 : prevIndex + 1);
    };
    // 왼쪽 버튼 클릭 핸들러
    const handleLeftButtonClick = () => {
        setStorylandIndex((prevIndex) => {
            if (prevIndex === -1 && storylandBookListIndex !== 0) {
                setStorylandBookListIndex(storylandBookListIndex - 1);
                return 9;
            } else if (prevIndex >= 0 && prevIndex <= 9) {
                return prevIndex - 1;
            }
            return -1;
        });
        // setStorylandIndex(prevIndex =>  prevIndex <= 0 ? -1 : prevIndex - 1);
    };

    useEffect(() => {
        if (end) {
            setIsBackgroundImageChanged(true);
            const interval = setInterval(() => {
                onEnd();
            }, 3000);

            return () => {
                clearInterval(interval);
            };
        } else {
            setIsBackgroundImageChanged(false);
        }
    }, [end]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((prevCountDown) => prevCountDown - 1);
        }, 1000);

        if (countDown === 0) {
            clearInterval(interval);
            // 2024-02-16 | OngoingModal
            setShowOngoingModal(true);
        }

        return () => clearInterval(interval);
    }, [countDown]);

    useEffect(() => {
        if (end) {
            setIsBackgroundImageChanged(true);
            const interval = setInterval(() => {
                onEnd();
            }, 3000);

            return () => {
                if (audioRef.current) {
                    audioRef.current.pause().catch((error) => {
                        console.error('오디오 멈춤에 실패했습니다.', error);
                    });
                    audioRef.current.currentTime = 0; // 오디오 시작 지점으로 재설정
                }
                clearInterval(interval);
            };
        } else {
            if (audioRef.current) {
                audioRef.current.volume = 0.2; // 볼륨을 20%로 설정
                audioRef.current.play().catch((error) => {
                    console.error('오디오 재생에 실패했습니다.', error);
                });
            }
            setIsBackgroundImageChanged(false);
        }
    }, [end]);

    // 이전 페이지로 이동하는 함수
    const goToPrevPage = () => {
        // changePage(storylandIndex - 1);
        handleLeftButtonClick();
        // setStorylandIndex(storylandIndex - 1)
    };

    // 다음 페이지로 이동하는 함수
    const goToNextPage = () => {
        // changePage(storylandIndex + 1);
        handleRightButtonClick();
        // setStorylandIndex(storylandIndex + 1)
    };

    return (
        <section
            className={`books-section ${
                isBackgroundImageChanged ? 'background-changed' : ''
            }`}
        >
            <audio
                ref={audioRef}
                src="/audio/AIMagickid.mp3"
                loop
            />
            <button
                className="music-play-btn"
                onClick={handleAudioPlay}
            >
                <figure>
                    <img
                        src={
                            isPlaying
                                ? '/images/music-on.svg'
                                : '/images/music-off.svg'
                        }
                        alt="music-play"
                    />
                </figure>
            </button>
            {/* NOTE: 2024-01-24 수정 버튼 css변경되어야함 */}
            {flag === 'story' && (
                <>
                    <h3 className={`bookloading-title on`}>{title.story} ☻</h3>
                    <h3 className={`bookloading-title-sub`}>{title.bg} ☻</h3>
                </>
            )}
            {flag === 'bg' && (
                <>
                    <h3 className={`bookloading-title`}>{title.story} ☻</h3>
                    <h3 className={`bookloading-title-sub on`}>{title.bg} ☻</h3>
                </>
            )}
            {flag === 'title' && (
                <h3 className={`bookloading-title on`}>{title} ☻</h3>
            )}
            <ProgressBar onProgressComplete={handleProgressComplete} />
            {/* 완성된 후 동작 안붙여놔서 일단 주석 */}
            <div className="clock">
                <figure>
                    <img
                        src={'/images/clock.svg'}
                        alt="clock"
                    />
                </figure>
                <p className={`count-down ${countDown <= 10 ? 'red' : ''}`}>
                    {countDown}
                </p>
            </div>

            <p className="waiting-txt">
                잠깐 스토리 랜드 동화를 감상해보아요! 더 많은 동화를 보고싶다면
                스토리랜드로 오세요!
            </p>

            <div className="storyland-container">
                <p className="storyland-book-text origin">
                    {storylandBookText}
                    <span className="bg-stroke">{storylandBookText}</span>
                </p>
                <div className="book-container">
                    <div className="page">
                        <img
                            src={storylandBook}
                            alt=""
                        />
                    </div>
                </div>
            </div>

            <figure className="shelf book-left">
                <img
                    src="/images/books-left.svg"
                    alt="left"
                />
            </figure>
            <figure className="shelf book-right">
                <img
                    src="/images/books-right.svg"
                    alt="right"
                />
            </figure>
            {/* <figure className="chat-bubble">
                <img
                    src={'/images/bubble.svg'}
                    alt="말풍선"
                />
            </figure> */}
            <figure className="girl-left">
                <img
                    src={'/images/gitl-left.svg'}
                    alt=""
                />
            </figure>
            <figure className="boy-right">
                <img
                    src={'/images/boy-right.svg'}
                    alt=""
                />
            </figure>
            <div className="btn-container">
                <button
                    className="btn"
                    id="prevPage"
                    onClick={goToPrevPage}
                >
                    <figure>
                        <img
                            src="/images/prev.svg"
                            alt="prev"
                        />
                    </figure>
                    <span>이전 페이지</span>
                </button>
                <button
                    className="btn"
                    id="nextPage"
                    onClick={goToNextPage}
                >
                    <span>다음 페이지</span>
                    <figure>
                        <img
                            src="/images/next.svg"
                            alt="next"
                        />
                    </figure>
                </button>
            </div>
            {/* 2024-02-16 | OngoingModal */}
            {showOngoingModal && (
                <OngoingModal onClose={() => setShowOngoingModal(false)} />
            )}
        </section>
    );
};

export default BookLoading;
